/**
 * 轻部落定制的首页
 */
import { Toast } from 'vant'
import IndexTabbar from '@/components/common/index-tabbar/index.vue'

export default {
  name: 'home-index',
  components: {
    IndexTabbar,
  },
  data() {
    return {
      banner1: require('../../assets/images/qingbuluo-home-index/banner1.png'),
      banner2: require('../../assets/images/qingbuluo-home-index/banner2.png'),
      banner3: require('../../assets/images/qingbuluo-home-index/banner3.png'),
      entrances: [
        { name: '我的团队', img: require('./../../assets/images/qingbuluo-home-index/my-team.png'), link: 'my-agents' },
        { name: '我的业绩', img: require('./../../assets/images/qingbuluo-home-index/rebate.png'), link: '' },
        { name: '商品入口', img: require('./../../assets/images/qingbuluo-home-index/product-entrance.png'), link: 'qingbuluo-product-list' },
        { name: '防伪查询', img: require('./../../assets/images/qingbuluo-home-index/search.png'), link: '' },
        { name: '邀请成员', img: require('./../../assets/images/qingbuluo-home-index/add-agent.png'), link: 'agent-invite' },
        { name: '订单审核', img: require('./../../assets/images/qingbuluo-home-index/order-auth.png'), link: 'order-review' },
        { name: '品牌介绍', img: require('./../../assets/images/qingbuluo-home-index/intro.png'), link: '' },
        { name: '领袖风采', img: require('./../../assets/images/qingbuluo-home-index/leader-view.png'), link: '' },
      ]
    }
  },

  methods: {
    on2Entrance(item) {
      if (!item.link) {
        Toast('该功能暂未开放~')
        return
      }
      this.$router.push({ name: item.link })
    }
  },

  created() {
    //  每次刷新时都去请求用户授权,防止用户页面改变
    this.$store.dispatch('user/getUserData')
  },
}